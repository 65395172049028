@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
body {
  font-family: "Poppins", sans-serif;
}

.main-content {
  width: calc(100% - 330px);
  margin-left: auto;
  padding: 20px;
}
.MainContentInfo {
  padding: 40px 0;
}

.MainContentInfo h1 {
  margin: 0 0 10px 0;
  color: #111;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 50px;
}

.MainContentInfo h1 span {
  font-weight: 700;
  color: #3b8bd0;
}
p.headlineMessage {
  font-size: 18px;
  margin: 0;
  font-weight: 500;
}

.chartbtnWrap {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 9;
  margin-bottom: 50px;
}

.btn-wrap {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: auto;
}

.btn-wrap button {
  border-radius: 8px;
  border: 1px solid rgba(17, 17, 17, 0.06);
  color: #000;
  width: 50px;
  height: 35px;
  cursor: pointer;
  font-size: 14px;
}

.btn-wrap button.active {
  color: #fff;
  background: #3b8bd0;
  box-shadow: -1px 3px 16px -3px rgba(0, 0, 0, 0.06);
}
.main-wrap {
  display: flex;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  background: #111111;
  height: 100%;
  padding: 20px;
  width: 250px;
  z-index: 999;
  overflow: auto;
}

.sidebarMenu {
  position: absolute;
  top: 0;
  right: -40px;
  display: none;
}

.sidebarmenu {
  padding: 0;
  list-style: none;
  margin: 0;
  margin: 0 0 60px 0;
}

.sidebarmenu li a {
  padding: 15px;
  display: block;
  border-radius: 10px;
  color: #fff;
  background: #111;
  margin: 2px 0;
  transition: all 0.5s;
}

a {
  text-decoration: none;
}

.sidebarmenu li a:hover,
.sidebarmenu li a.active {
  background: #3b8bd0;
}

.Nav-logo h1 {
  color: #fff;
  margin: 0;
}

.Nav-logo {
  margin: 0px 0 30px 0;
  border-bottom: 1px solid #fff;
  display: block;
  padding: 10px 0 40px;
}
body:after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #000;
  opacity: 0;
  transition: all 0.5s;
}



body.sidebar-open:after {
  opacity: 0.7;
  transition: all 0.5s;
  z-index: 99;
}
@media (max-width: 991px) {
  .sidebarMenu {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 20px;
    background: #3b8bd0;
    color: #fff;
    padding: 10px;
  }
  .sidebarMenu svg {
    width: 100%;
    height: 100%;
  }
  .MainContentInfo h1 {
    font-size: 28px;
    line-height: initial;
    text-align: center;
  }

  p.headlineMessage {
    font-size: 16px;
    width: 100%;
    text-align: center;
  }

  .chartbtnWrap {
    flex-wrap: wrap;
    justify-content: center;
  }

  .btn-wrap {
    margin: 10px 0 0 0;
  }
  .main-content {
    width: 100%;
  }

  .sidebar {
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }
  .sidebar-open .sidebar {
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;
  }
}
